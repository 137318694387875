import React, { useState } from 'react';

const Match = ({ props, index, parentCallback }) => {
  const [active] = useState(true);

  return (
    <div className={`match ${active ? 'active' : 'disabled'}`} data-match-id="@match.UnibetId">

      <div className="teams">
        <div className="team">
          <div className="team-name">{props.homeTeam}</div>
        </div>
        <div className="team">
          <div className="team-name">{props.awayTeam}</div>
        </div>
      </div>
      <div className="bets">
        {props.bets.map((bet, index) => (
          <div className={`bet ${bet.best ? 'active' : 'disabled'}`}>
            <div className="bet-button">
              <div className="bet-odds">{bet.odds}</div>
            </div>
            <div className="bb">
              <div className="bb-amount">{bet.bb}%</div>
            </div>
          </div>
        ))}
      </div>
    </div>

  );
}

export default Match;