import React, { useState } from 'react';
import { useAsync } from 'react-async';

import './App.css';

import Data from './Data';

import Match from './components/Match';
import Total from './components/Total';

import logo from './assets/logo.png';

//import "swiper/swiper-bundle.min.css";
//import "swiper/swiper.min.css";

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

SwiperCore.use([Navigation, Pagination]);

// const loadUsers = async () =>
//   await fetch("https://valuebase.io/api/sef/comboapi?market=nl&league=1000094980")
//     .then(res => (res.ok ? res : Promise.reject(res)))
//     .then(res => res.json())

const loadData = async () => {
  const queryParams = new URLSearchParams(window.location.search);
  let market = queryParams.get('market');
  let league = queryParams.get('league');
  let matches = queryParams.get('matches');
  let bets = queryParams.get('bets');

  if (market == null) market = "nl";
  if (league == null) league = "";
  if (matches == null) matches = 3;
  if (bets == null) bets = 5;

  return Data.loadMatches(market, league, matches, bets);
}

function App() {
  let { data, isLoading } = useAsync({ promiseFn: loadData });
  const [timestamp, setTimestamp] = useState(0);

  const handleCallback = (data) => {
    setTimestamp(new Date());
  };

  if (isLoading) return (<div className="loading">Loading...</div>);
  // if (error) return `Something went wrong: ${error.message}`;

  if (data) {
    return (
      <>
        <header>
        Favoriet bij spelers van Unibet
        </header>
        <div className="App">
          <main>
            <div className="matches">
              <Swiper
                //modules={[Navigation]}
                className="group"
                spaceBetween={0}
                slidesPerView={1}
                slidesPerGroup={1}
                navigation={true}
                pagination={{ clickable: true }}
                autoHeight={true}
                loop={true}
                onSwiper={(swiper) => {
                  //swiperRef = swiper;
                  //resize();
                }}>

                {data.matches.map((match, index) => (
                  <SwiperSlide key={match.id}>
                    <Match timestamp={timestamp} props={match} index={index} parentCallback={handleCallback} />
                  </SwiperSlide>
                ))}

              </Swiper>
            </div>
            <Total timestamp={timestamp} props={data} />
          </main>
          <div className="disclaimer">WAT KOST GOKKEN JOU? <span className='line-2'>STOP OP TIJD. 18+</span></div>
        </div>
        <div className="background">
          <div className="background-triangle-gray"></div>
          <div className="background-triangle-black"></div>
          <img className='logo' src={logo} alt="logo" />
        </div>
      </>
    );
  }
}

export default App;
